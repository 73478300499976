/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$color-red: #ec0606;
$color-green: #3a9925;
$color-yellow: #aca709;
$color-blue: #052fba;
$color-brown: #460202CD;
$color-purple: #8f00ff;
$color-orange: #FD6902CD;
$color-pink: #F6B4E3;


.swiper-scrollbar {
  opacity: 1 !important;
}

/*Wraperclass for the divicon*/
.poi-icon {
  height: 30px;
  width: 30px;
  display: inline-block;
  .poi-icon-content {
    order: 1;
    position: relative;
    background-image: url("./assets/icon/car-icon.png");
    border-radius: 50%;
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 6px;
  }

  .poi-icon-arrow {
    order: 1;
    position: absolute;
    font-size: 29px;
    white-space: nowrap;
  }
}

.poi-icon-red {
  @extend .poi-icon;
  .poi-icon-content {
    background-color: $color-red;
    color: #ffffff;
  }
  .poi-icon-arrow {
    color: $color-red;
  }
}
.poi-icon-green {
  @extend .poi-icon;
  .poi-icon-content {
    background-color: $color-green;
    color: #ffffff;
  }
  .poi-icon-arrow {
    color: $color-green;
  }
}
.poi-icon-yellow {
  @extend .poi-icon;
  .poi-icon-content {
    background-color: $color-yellow;
    color: #ffffff;
  }
  .poi-icon-arrow {
    color: $color-yellow;
  }
}

.poi-icon-blue {
  @extend .poi-icon;
  .poi-icon-content {
    background-color: $color-blue;
    color: #ffffff;
  }
  .poi-icon-arrow {
    color: $color-blue;
  }
}

.poi-icon-purple {
  @extend .poi-icon;
  .poi-icon-content {
    background-color: $color-purple;
    color: #ffffff;
  }
  .poi-icon-arrow {
    color: $color-purple;
  }
}

.poi-icon-brown {
  @extend .poi-icon;
  .poi-icon-content {
    background-color: $color-brown;
    color: #ffffff;
  }
  .poi-icon-arrow {
    color: $color-brown;
  }
}

.poi-icon-orange {
  @extend .poi-icon;
  .poi-icon-content {
    background-color: $color-orange;
    color: #ffffff;
  }
  .poi-icon-arrow {
    color: $color-orange;
  }
}

.poi-icon-pink {
  @extend .poi-icon;
  .poi-icon-content {
    background-color: $color-pink;
    color: #ffffff;
  }
  .poi-icon-arrow {
    color: $color-pink;
  }
}


.poi-icon-up_right {
  .poi-icon-arrow {
    top: -15px;
    left: 15px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.poi-icon-right {
  .poi-icon-arrow {
    left: 20px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.poi-icon-down_right {
  .poi-icon-arrow {
    top: 15px;
    left: 15px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}
.poi-icon-down {
  .poi-icon-arrow {
    top: 20px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.poi-icon-down_left {
  .poi-icon-arrow {
    top: 15px;
    left: -15px;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
  }
}
.poi-icon-left {
  .poi-icon-arrow {
    left: -20px;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
.poi-icon-up_left {
  .poi-icon-arrow {
    top: -15px;
    left: -15px;
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg);
  }
}
.poi-icon-up {
  .poi-icon-arrow {
    top: -20px;
  }
}
.poi-icon-all {
  .poi-icon-arrow {
    display: none;
  }
}

.marker-detail {
  --backdrop-opacity: 1;
  backdrop-filter: blur(3px);

  &::part(backdrop) {
    background: #000;
  }

  &::part(handle) {
    background: var(--ion-color-primary);
    width: 150px;
    height: 6px;
  }

  &::part(content) {
    border-radius: 0px;
    box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}
